<template>
  <v-card tile elevation="0" style="text-align: center">
    <v-list class="transparent">
      <v-list-item-subtitle
        style="font-size: 14px; font-weight: bold; color: #066a8c"
        >KREWS WALLET</v-list-item-subtitle
      >
      <router-link :to="`/krews-wallet`" v-if="isSuperAdmin">
        <v-list-item-icon style="margin: 0">
          <v-list-item-title
            class="font-weight-black text-left title"
            style="color: #197091"
          >
            {{ totalOnlineSales | toCurrency }}
          </v-list-item-title>
        </v-list-item-icon>
      </router-link>
      <v-list-item-icon style="margin: 0" v-else>
        <v-icon style="color: #5b95ae" large>mdi-wallet</v-icon>
        <v-list-item-title
          class="font-weight-black text-left title"
          style="color: #197091"
        >
          {{ totalOnlineSales | toCurrency }}
        </v-list-item-title>
      </v-list-item-icon>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: { reload: { type: Boolean, default: false } },
  watch: {
    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getOnlineSales();
        }
      },
    },
  },
  components: {},
  data() {
    return {
      totalOnlineSales: 0,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
    isSuperAdmin() {
      const user = this.$store.getters.userInfo;
      if (user.role_id === 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getOnlineSales();
  },
  methods: {
    getOnlineSales() {
      this.showLoader("Loading");
      this.$http.get(`venues/sales/krews`).then((response) => {
        if (response.status == 200 && response.data.status == true) {
          this.totalOnlineSales = response.data.data.sales;
          this.hideLoader();
        }
      });
    },
  },
};
</script>

<style></style>
