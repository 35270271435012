<template>
  <div class="d-flex justify-space-between align-center mb-8">
    <div class="d-flex p-4 rounded bg-white bordered w-fit tabs">
      <template v-for="(tab) in tabs">
        <div
            v-if="checkWritePermission(tab.permission)"
            v-bind:key="tab.name"
            class="nv_item d-flex pointer tab"
            @click="gotoPage(tab.path[0])"
        >
          <SvgIcon
              :class="{
                    'qp-tab-nav-is-active':tab.path.includes($route.path),
                    'qp-tab-nav':!tab.path.includes($route.path)
                  }"
              :text="tab.name"
              class="text-thin-gray"
          >
            <template v-slot:icon>
              <component :is="tab.icon" :component="tab.icon"/>
            </template>
          </SvgIcon>
        </div>
      </template>
      <div
          v-if="checkWritePermission(this.$modules.sales.logs.slug)"
          v-bind:key="'Configuration'"
          class="nv_item d-flex pointer"
          @click="configDialog = true"
      >
        <SvgIcon
            class="qp-tab-nav text-thin-gray"
            text="Configuration"
        >
          <template v-slot:icon>
            <ConfigIcon/>
          </template>
        </SvgIcon>
      </div>
    </div>
    <div class="text-center" v-if="checkReadPermission(this.$modules.sales.wallet.slug)">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex align-center wallet_btn"  v-bind="attrs" v-on="on">
            <WalletIcon/>
            <div class="wallet_btn_text">Wallet </div>
            <v-icon color="#112A46">mdi-chevron-right</v-icon>
          </div>
        </template>
        <online-sales></online-sales>
        <krews-sales></krews-sales>
      </v-menu>
    </div>
    <sales-configuration
        @close="configDialog = false"
        :viewConfig="configDialog"
        :productType="'academy'"
    ></sales-configuration>
  </div>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import OnlineSales from "@/views/Sales/OnlineSales.vue";
import KrewsSales from "@/views/Sales/KrewsSales.vue";
import CancelIcon from "@/assets/images/finance/cancels.svg"
import ConfigIcon from "@/assets/images/finance/configurations.svg"
import CreditIcon from "@/assets/images/finance/credits.svg"
import InvoiceIcon from "@/assets/images/finance/invoices.svg"
import OrderIcon from "@/assets/images/finance/orders.svg"
import RefundIcon from "@/assets/images/finance/refunds.svg"
import SaleIcon from "@/assets/images/finance/sales.svg"
import WalletIcon from "@/assets/images/finance/wallet.svg"
import SalesConfiguration from "@/components/Settings/SalesConfiguration.vue";

export default {
  components: {
    SalesConfiguration,
    KrewsSales,
    OnlineSales,
    CancelIcon,
    ConfigIcon,
    CreditIcon,
    InvoiceIcon,
    OrderIcon,
    RefundIcon,
    SaleIcon,
    SvgIcon,
    WalletIcon,
  },
  methods: {
    gotoPage(path) {
      this.$router.push(path);
    },
  },
  data() {
    return {
      configDialog:false,
      tabs: [
        {
          name: "Sales",
          icon: SaleIcon,
          path: ["/sales"],
          permission: this.$modules.sales.graph.slug
        },
        {
          name: "Orders",
          icon: OrderIcon,
          path: ["/logs"],
          permission: this.$modules.sales.logs.slug
        },
        {
          name: "Invoices",
          icon: InvoiceIcon,
          path: ["/invoices"],
          permission: this.$modules.sales.logs.slug
        },
        {
          name: "Credits",
          icon: CreditIcon,
          path: ["/credits"],
          permission: this.$modules.sales.credits.slug
        },
        {
          name: "Refunds",
          icon: RefundIcon,
          path: ["/refunds"],
          permission: this.$modules.sales.refunds.slug
        },
        {
          name: "Cancellations",
          icon: CancelIcon,
          path: ["/cancellations"],
          permission: this.$modules.sales.void.slug
        },
        // {
        //   name: "Configuration",
        //   icon: ConfigIcon,
        //   path: ["/sales/configurations"],
        //   permission: this.$modules.pos.dashboard.slug
        // },
      ],
    }
  },
  computed: {
    cartCount() {
      return this.$store.getters.getCartCount;
    },
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &:after {
    border-right: 1px solid #DCDCDC;
    content: " ";
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.wallet_btn{
  border: solid 1px #112A46 !important;
  background: #112A461A;
  border-radius: 4px;
  padding:14px
}

.wallet_btn_text{
  padding-left: 2px;
  padding-right: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
}
</style>

